
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import SubmissionChart from "./components/submissionChart.vue";

interface Homework {
  id: string;
  subject: { _id: string; name: string };
  module: string;
  title: string;
  desc: string;
  files?: Array<string>;
  marked: boolean;
  deadline: string;
  createdAt: string;
  updatedAt: string;
  spec?: boolean;
  submited: boolean;
  submission: { createdAt: string; files: Array<string>; answer: string };
}

export default defineComponent({
  name: "todo-page",
  components: { SubmissionChart },
  setup() {
    const { t } = useI18n();
    const loading = ref(true);
    const store = useStore();
    const currentStudent = store.getters.currentStudent;
    const completedHomeworks = ref<number>(0);

    const homeworks: Array<Homework> = [];
    ApiService.get(`/lms/homework/parent/${currentStudent._id}`)
      .then(({ data }) => {
        data.forEach((exercise) => {
          homeworks.push({
            id: exercise._id,
            subject: { name: exercise.subject.name, _id: exercise.subject._id },
            module: exercise.module,
            desc: exercise.desc,
            marked: exercise.marked,
            title: exercise.title,
            createdAt: exercise.createdAt,
            deadline: exercise.deadline,
            updatedAt: exercise.updatedAt,
            files: exercise.submission ? exercise.submission.files : [],
            spec: exercise.spec,
            submited: exercise.submited,
            submission: exercise.submission,
          });
        });
        for (const item of homeworks) {
          filteredHomeworks.value.push(item);
        }

        completedHomeworks.value = 0;
        loading.value = false;

        homeworks.forEach((homework: Homework) => {
          if (homework.submited) completedHomeworks.value++;
        });
      })
      .catch((e) => console.log(e));

    const filteredHomeworks = ref<Array<Homework>>([]);
    const search = ref<string>("");

    const getFileIconName = (file: string): string => {
      const type = file.split(".")[1];
      if (
        ["pdf", "png", "jpg", "ppt", "xls", "txt", "gif", "doc"].includes(type)
      )
        return type;
      return "file";
    };

    const getShortFileName = (file: string): string => {
      if (file.length > 20)
        return "..." + file.substring(file.length - 15, file.length);
      return file;
    };

    const MS_IN_WEEK = 1000 * 60 * 60 * 24 * 7;

    const getDeadlineTagColor = (date: Date): string => {
      const deff = new Date(date).getTime() - Date.now();

      if (deff < 0) return "danger";
      else if (Math.round(deff / MS_IN_WEEK) == 0) return "warning";
      return "success";
    };

    const searchHomeworks = () => {
      filteredHomeworks.value = homeworks.filter((homework) => {
        const toSearch = search.value.toLowerCase();
        return (
          homework.title.toLowerCase().includes(toSearch) ||
          homework.subject.name.toLowerCase().includes(toSearch)
        );
      });
    };

    const finishedHomeworks = computed(() =>
      filteredHomeworks.value.filter(
        (homework) => homework.files && homework.files.length != 0
      )
    );

    const manquantHomeworks = computed(() =>
      filteredHomeworks.value.filter(
        (homework) => !homework.files || homework.files.length == 0
      )
    );

    onMounted(() => {
      setCurrentPageTitle(t("todo"));
    });

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredHomeworks.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredHomeworks.value.slice(startIndex - 1, endIndex);
    });

    const finishedCurrentPage = ref<number>(1);

    const finishedTotalPages = computed(() =>
      Math.ceil(finishedHomeworks.value.length / perPage)
    );

    const finishedPageRecords = computed(() => {
      const startIndex = perPage * (finishedCurrentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return finishedHomeworks.value.slice(startIndex - 1, endIndex);
    });

    const manquantCurrentPage = ref<number>(1);

    const manquantTotalPages = computed(() =>
      Math.ceil(manquantHomeworks.value.length / perPage)
    );

    const manquantPageRecords = computed(() => {
      const startIndex = perPage * (manquantCurrentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return manquantHomeworks.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      getFileIconName,
      getShortFileName,
      getDeadlineTagColor,
      moment,
      search,
      searchHomeworks,
      loading,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      finishedTotalPages,
      finishedPageRecords,
      manquantTotalPages,
      manquantPageRecords,
      finishedCurrentPage,
      manquantCurrentPage,
      filteredHomeworks,
      manquantHomeworks,
      finishedHomeworks,
      completedHomeworks,
      homeworks,
    };
  },
});
